.chart-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vmin
}

.addr-input{
  background: transparent;
  border: none;
  border-bottom: solid #1876d1;
  min-width: 350px;
  text-align: center;
  color: white;
  font-size: 16px;
}

.slider-container{
    width: 97%;
    display: flex;
    justify-content: end;
    padding-bottom: 16px;
}
.slider-text{
  font-size: 13px;
  text-align: center;
}
.slider-text-container{
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-chart-container{
  width: 80%;
  min-width: 250px;
  height: fit-content;
  overflow: auto;
}

.div-pool-select{
  width: 100%;
  display: flex;
  justify-content: right;
}

.pool-select{
background: transparent;
color: white;
border: none;
border-bottom: solid;
font-size: 3vmin;
}

@media (max-width:640px) {
.chart-container{
      height: 80vmax;
      display: flex;
      justify-content: center;
      align-items: center;
      
}
.inner-chart-container{
  
}
.div-pool-select{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.pool-select{
  font-size: 20px;
}}