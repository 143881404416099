.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
}

.div-logo {
  height: 5vmin;
  width: 80%;
  justify-content: left;
  display: flex;
  padding-bottom: 16px;
}



.App-header {
  background-color: #061c6f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 5vmin 15vmin;
}

.App-link {
  color: #61dafb;
}

@media (max-width:640px) {

  .App-header {
    padding: 4px;
  }
  .div-logo{
    width: 100%;
    padding-bottom: 10px;
    padding-top: 8px;
    justify-content: center;
  }
}